import React from "react";
import { Button, Chip } from "@mui/material";
import { grey } from "@mui/material/colors";

const NavButton = ({ item, isActive, navigate }) => {
  const active = isActive(item.path);
  return (
    <Button
      onClick={() => navigate(item.path)}
      fullWidth
      sx={{
        justifyContent: "start",
        textTransform: "none",
        color: active ? "common.white" : "text.secondary",
        fontWeight: 400,
        backgroundColor: active ? "primary.main" : "transparent",
        "&:hover": {
          backgroundColor: active ? "primary.main" : grey[300],
        },
      }}
      startIcon={item.icon}
    >
      {item.text}
      {item.is_admin && (
        <Chip
          label="Admin"
          size="small"
          sx={{
            transition: "all 0.3s ease",
            backgroundColor: active ? "background.paper" : "primary.main",
            color: active ? "black" : "common.white",
            ml: 1,
            height: 20,
            fontSize: "0.75rem",
          }}
        />
      )}
    </Button>
  );
};

export default NavButton;