import { Link, useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useOrder } from "../../../hooks/useOrder";
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  Badge,
  Grid,
  Button,
  Divider,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Chip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import usePriceCalculator from "../../../components/priceCalculator";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import { useTheme } from "@emotion/react";
import { useAuth } from "../../../hooks/useAuth";
import { BuildCircle } from "@mui/icons-material";
import StatusBadge from "../../../components/StatusBadge";

function OrderDetails() {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { userDetails } = useAuth();
  const { getOrderById, error, loading, updateOrderStatus, downloadOrderPDF } =
    useOrder();
  const [order, setOrder] = useState(null);
  const { calculatePrice } = usePriceCalculator();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const fetchedOrder = await getOrderById(orderId);
        setOrder(fetchedOrder);
      } catch (err) {
        console.error("Error fetching order:", err);
      }
    };

    if (orderId) {
      fetchOrder(); // Ensure this is called only once when orderId changes
    }
  }, [orderId, getOrderById]);

  useEffect(() => {
    if (order?.OrderItems && order?.OrderItems.length > 0) {
      order?.OrderItems.forEach((item) => {
        calculatePrice(
          item.quantity,
          item.Motif.width,
          item.Motif.height,
          item.Motif.colors,
          item.id,
          userDetails.user_id
        );
      });
    }
  }, [order, calculatePrice, userDetails.user_id]);

  const handleStatusChange = async (status) => {
    setAnchorEl(null);
    try {
      await updateOrderStatus(order.id, status);
      setOrder({ ...order, status });
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      await downloadOrderPDF(orderId);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Paper elevation={3} sx={{ padding: 4, margin: "auto" }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }

  if (!order) {
    return (
      <Paper elevation={3} sx={{ padding: 4, margin: "auto" }}>
        <Alert severity="warning">No order found.</Alert>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        margin: "auto",
        borderRadius: 2,
      }}
    >
      {/* Order Header with Status */}
      <Grid container spacing={1}>
        <Grid item xs={12} md={10}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h4" color="text.light">
              {t("Dashboard.OrderHistory.Order")} /
            </Typography>
            <Typography fontWeight={500} variant="h4" sx={{ ml: 1 }}>
              #{order.id}
            </Typography>
            <StatusBadge status={order.status} sx={{ ml: 2 }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={2} textAlign={{ xs: "left", md: "right" }}>
          <Tooltip title={t("Dashboard.OrderHistory.ChangeStatus")} arrow>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{
                backgroundColor: theme.palette.primary.light,
                borderRadius: 2,
                padding: 1,
                "& .MuiTouchRipple-root span": {
                  borderRadius: 2,
                },
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Menu
          disableScrollLock
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              },
            }}
          >
            {StatusBadge.keys.map((statusKey) => (
              <MenuItem
                key={statusKey}
                onClick={() => handleStatusChange(statusKey)}
              >
                <StatusBadge status={statusKey} variant="menu" sx={{ mr: 2 }} />
                {t(StatusBadge.statuses[statusKey])}
              </MenuItem>
            ))}
          </Menu>

          {/* Download PDF Button */}
          <Tooltip title={t("Dashboard.OrderHistory.DownloadPDF")} arrow>
            <IconButton
              onClick={(e) => {
                handleDownloadPDF(order.id);
              }}
              sx={{
                backgroundColor: theme.palette.success.light,
                borderRadius: 2,
                padding: 1,
                "& .MuiTouchRipple-root span": {
                  borderRadius: 2,
                },
                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                },
                ml: 1,
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* Left Section (Order Item and Summary) */}
        <Grid item xs={12} lg={8}>
          {/* Order Item */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography variant="h6">
                {t("Dashboard.OrderHistory.OrderItem")}
              </Typography>
              {order.assembly_included && (
                <Chip
                  icon={<BuildCircle />}
                  label={t("Dashboard.OrderHistory.AssemblyIncluded")}
                  color="warning"
                  sx={{
                    ml: 2,
                    fontWeight: "500",
                    backgroundColor: theme.palette.warning.main, // Use a noticeable color
                    // paddingX: 1.5, // Extra padding for emphasis
                  }}
                />
              )}
            </Box>
            <Divider sx={{ my: 1, mb: 2 }}></Divider>
            {order?.OrderItems.map((item, index) => (
              <Box key={item.id} sx={{ mb: 2 }}>
                <Link
                  to={`/dashboard/motif/${item.Motif.id}`}
                  state={{ from: "order-details", orderId: order.id }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Grid container spacing={2} alignItems="center">
                    {/* Left Section: Motif Details */}
                    <Grid item xs={12} sm={7} md={8}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          component="img"
                          src={item.Motif.image_url || "/placeholder.png"}
                          alt={item.Motif.name}
                          sx={{
                            width: { xs: "80px", sm: "100px" },
                            maxWidth: "100px",
                            height: "auto",
                            maxHeight: "100px",
                            mr: 2,
                            p: 1,
                            backgroundColor: "grey",
                            borderRadius: 1,
                            objectFit: "contain",
                          }}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle1" noWrap>
                            {item.Motif.name}
                          </Typography>
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Tooltip
                              title={
                                item.Motif.active ? "" : t("Article.Inactive")
                              }
                            >
                              <Chip
                                label={item.Motif.article_number}
                                size="small"
                                sx={{
                                  backgroundColor: item.Motif.active
                                    ? theme.palette.secondary.main
                                    : theme.palette.error.main,
                                  color: item.Motif.active
                                    ? "text.main"
                                    : "white",
                                  fontWeight: 500,
                                  textTransform: "none",
                                  minWidth: "auto",
                                }}
                              />
                            </Tooltip>
                            <Typography
                              variant="body2"
                              fontSize="0.8rem"
                              color="text.light"
                              noWrap
                            >
                              {item.Motif.width} x {item.Motif.height}
                            </Typography>
                          </Box>

                          <Typography variant="body2" noWrap>
                            {t("Dashboard.PriceCalculator.Quantity")}:{" "}
                            {item.quantity}
                          </Typography>
                          <Typography variant="body2" noWrap>
                            {t("Dashboard.Motif.PricePerAbbr")}:{" "}
                            {parseFloat(item.price_per_unit)} kr
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    {/* Right Section: Pricing */}
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      md={4}
                      sx={{ textAlign: { xs: "left", sm: "right" } }}
                    >
                      <Typography variant="subtitle1" noWrap>
                        {t("Dashboard.PriceList.LumpSum")}: +{" "}
                        {parseFloat(item.preparation_cost)} kr
                      </Typography>
                      <Typography variant="subtitle1" noWrap>
                        {t("Dashboard.OrderHistory.Total")}:{" "}
                        {parseFloat(item.total)} kr
                      </Typography>
                    </Grid>
                  </Grid>
                  {index !== order.OrderItems.length - 1 && (
                    <Divider sx={{ my: 1 }} />
                  )}
                </Link>
              </Box>
            ))}
            <Divider sx={{ my: 2 }} />
          </Paper>

          {/* Order Summary */}
          <Paper sx={{ boxShadow: "none", padding: 3 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.OrderSum")}
            </Typography>
            <Divider sx={{ mb: 2 }}></Divider>
            {order?.OrderItems.map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                >
                  <Typography color={"text.secondary"}>
                    {item.Motif.name} (x{item.quantity})
                  </Typography>
                  <Typography color={"text.secondary"} fontWeight={500}>
                    {parseFloat(item.total)} kr
                  </Typography>
                </Box>
              );
            })}
            <Divider sx={{ mt: 2 }}></Divider>
            {/* Grand Total */}
            <Box mt={1}>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Box>
                  <Typography variant="h6">
                    {t("Dashboard.OrderHistory.Total")}
                  </Typography>

                  <Typography
                    variant="body1"
                    fontSize={14}
                    color={"text.secondary"}
                    mt={-1}
                  >
                    {t("Checkout.VATExcluded")}
                  </Typography>
                </Box>

                <Typography variant="h6">
                  {parseFloat(order.total_amount)} kr
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography variant="body1" color={"text.secondary"}>
                  {t("Checkout.VAT")}
                </Typography>
                <Typography variant="body1" color={"text.secondary"}>
                  {parseFloat(
                    order.total_amount_with_vat - order.total_amount
                  ).toFixed(2)}
                  kr
                </Typography>
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography variant="body1" color={"text.secondary"}>
                  {t("Dashboard.OrderHistory.Total")}{" "}
                  {t("Checkout.VATIncluded")}
                </Typography>
                <Typography variant="body1" color={"text.secondary"}>
                  {parseFloat(order.total_amount_with_vat)} kr
                </Typography>
              </Box>
            </Box>

            {/* Display Notes Section */}
            {order.notes && (
              <Box sx={{ mt: 3 }}>
                <Typography variant="h6">
                  {t("Dashboard.OrderHistory.Notes")}
                </Typography>
                <Box display={"flex"} alignItems={"center"} sx={{ mt: 1 }}>
                  <Avatar src={order.User.avatar}>
                    {order.shipping_company_name.charAt(0)}
                  </Avatar>
                  <Box sx={{ ml: 1 }} display={"flex"} flexDirection={"column"}>
                    <Typography
                      variant="body2"
                      color="text.light"
                      fontSize={12}
                      sx={{ mb: -0.25 }}
                    >
                      {order.shipping_company_name
                        ? order.shipping_company_name
                        : order.shipping_firstname +
                          " " +
                          order.shipping_lastname}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {order.notes}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </Grid>

        {/* Right Section (Customer Details and Shipping Info) */}
        <Grid item xs={12} md={4}>
          {/* Customer Info */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.Customer")}
            </Typography>
            {order.shipping_company_name && (
              <Typography fontWeight={500}>
                {order.shipping_company_name}
              </Typography>
            )}
            <Typography>
              {order.shipping_firstname} {order.shipping_lastname}
            </Typography>
            <Typography color={"text.light"}>{order.User.email}</Typography>
          </Paper>

          {/* Shipping Info */}
          <Paper sx={{ boxShadow: "none", padding: 3, mb: 4 }}>
            <Typography variant="h6">
              {t("Dashboard.OrderHistory.ShippingAdd")}
            </Typography>
            <Typography>{order.shipping_address}</Typography>
            <Typography>
              {order.shipping_city}, {order.shipping_postal_code}
            </Typography>
            <Typography>{order.shipping_country}</Typography>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default OrderDetails;
