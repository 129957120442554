import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../hooks/useAuth";

const PasswordResetPage = () => {
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetPassword, validateResetToken } = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(null);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const result = await validateResetToken(resetToken);
      if (result && result.valid) {
        setIsTokenValid(true);
      } else {
        setIsTokenValid(false);
      }
    };

    if (resetToken) {
      checkTokenValidity();
    } else {
      setIsTokenValid(false);
    }
  }, [resetToken, validateResetToken]);

  useEffect(() => {
    if (isTokenValid === false) {
      navigate("/userrecovery");
    }
  }, [isTokenValid, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that passwords match
    if (newPassword !== confirmPassword) {
      setPasswordError(t("UserRecovery.PassMissmatch"));
      return;
    }
    setPasswordError("");

    setLoading(true);
    try {
      // Call the resetPassword hook function
      const data = await resetPassword(resetToken, newPassword);
      if (!data) {
        throw new Error("Reset password failed");
      }
      setSnackbarSeverity("success");
      setSnackbarMessage(
        data.message || t("UserRecovery.PassResetSuccess")
      );
      setSnackbarOpen(true);
      // Optionally navigate to login after a delay
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      setSnackbarSeverity("error");
      setSnackbarMessage(error.message || t("UserRecovery.PassError"));
      setSnackbarOpen(true);
    }
    setLoading(false);
  };

  if (isTokenValid === null) {
    return <div>{t("Loading")}</div>;
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mx: 2,
        }}
      >
        <Typography component="h1" variant="h4" fontWeight={"600"}>
          {t("UserRecovery.ResetPass")}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 2, width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label={t("UserRecovery.NewPass")}
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t("UserRecovery.ConfirmPass")}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            {t("UserRecovery.ResetPass")}
          </Button>
        </Box>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default PasswordResetPage;
