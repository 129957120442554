import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { CartContext } from "../../context/CartContext";
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  keyframes,
  TextField,
  Collapse,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "../../hooks/useUser";
import { useJsApiLoader } from "@react-google-maps/api";
import { useOrder } from "../../hooks/useOrder";
import CartList from "../../components/checkout/CartList";
import OrderDetails from "../../components/checkout/OrderDetails";
import CheckoutForm from "../../components/checkout/CheckoutForm";
import { Check, ClearRounded } from "@mui/icons-material";

const libraries = ["places"];

const CartPage = () => {
  const { cart, handleRemoveFromCart, handleClearCart } =
    useContext(CartContext);
  const { userDetails } = useAuth();
  const [applyAssembly, setApplyAssembly] = useState(false);
  const { user, getUserByID, updateUserProfile } = useUser();
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [animationStage, setAnimationStage] = useState("initial");
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [orderError, setOrderError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberDetails, setRememberDetails] = useState(false);
  const [notes, setNotes] = useState("");
  const [formFields, setFormFields] = useState({
    firstname: "",
    lastname: "",
    company_name: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { createOrder } = useOrder();
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAPS_API_KEY,
    libraries: libraries,
  });

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
  }

  useEffect(() => {
    if (userDetails && userDetails.user_id) {
      getUserByID(userDetails.user_id);
    }
  }, [userDetails, getUserByID]);

  useEffect(() => {
    if (user) {
      setFormFields({
        firstname: user.firstname || "",
        lastname: user.lastname || "",
        company_name: user.company_name || "",
        address: user.address || "",
        city: user.city || "",
        postal_code: user.postal_code || "",
        country: user.country || "",
      });
    }
  }, [user]);

  const validateFields = () => {
    let newErrors = {};
    const requiredFields = [
      "firstname",
      "lastname",
      "address",
      "city",
      "postal_code",
    ];

    requiredFields.forEach((field) => {
      if (!formFields[field]) {
        newErrors[field] = `${field.replace("_", " ")} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handlePlaceOrder = async () => {
    if (cart.length === 0) {
      setOrderError(true);
      setErrorMessage(t("Checkout.EmptyWarn"));
      return;
    }

    if (validateFields()) {
      setLoading(true);
      setAnimationStage("loading"); // Immediately set to loading

      setTimeout(async () => {
        try {
          if (rememberDetails) {
            await updateUserProfile(userDetails.user_id, {
              firstname: formFields.firstname,
              lastname: formFields.lastname,
              company_name: formFields.company_name,
              address: formFields.address,
              city: formFields.city,
              postal_code: formFields.postal_code,
              country: formFields.country,
            });
          }

          const orderItems = cart.map((item) => ({
            motif_id: item.id,
            quantity: item.quantity,
          }));

          const shippingDetails = {
            firstname: formFields.firstname,
            lastname: formFields.lastname,
            company_name: formFields.company_name,
            address: formFields.address,
            city: formFields.city,
            postal_code: formFields.postal_code,
            country: formFields.country,
            notes: notes || "",
            assembly_included: applyAssembly,
          };

          const newOrder = await createOrder({
            items: orderItems,
            shippingDetails,
          });

          if (!newOrder || !newOrder.id) {
            throw new Error("Order creation failed");
          }

          setOrderError(false);
          setErrorMessage("");
          setAnimationStage("success");

          setTimeout(() => {
            handleClearCart();
            setOrderPlaced(true);
            navigate(`/dashboard/order/${newOrder.id}`);
          }, 1000);
        } catch (error) {
          console.error("Failed to create order", error);
          let errorMsg = t("Checkout.OrderError");
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMsg = error.response.data.message;
          } else if (error.message) {
            errorMsg = error.message;
          }
          setOrderError(true);
          setErrorMessage(errorMsg);
          setAnimationStage("failure");
        } finally {
          setLoading(false);
        }
      }, 500);
    } else {
      // Validation failed
      setShowForm(true);
      setOrderError(true);
      setErrorMessage(t("Checkout.FormError"));
      setAnimationStage("failure");
      setLoading(false);
    }
  };

  const handleToggleAssembly = (event) => {
    setApplyAssembly(event.target.checked);
  };

  if (!user || Object.keys(user).length === 0) {
    return (
      <Container
        component="main"
        maxWidth="lg"
        sx={{ textAlign: "center", mt: 10 }}
      >
        <Paper elevation={3} sx={{ padding: 5, borderRadius: 2 }}>
          <Typography variant="h4" fontWeight={500} gutterBottom>
            {t("Checkout.NonUserTitle")}
          </Typography>
          <Typography variant="body1" mb={3}>
            {t("Checkout.NonUserSubtitle")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/login"
          >
            {t("LogIn")}
          </Button>
        </Paper>
      </Container>
    );
  }

  const bounceAnimation = keyframes`
    0% { transform: scale(0); opacity: 0; }
    50% { transform: scale(1.2); opacity: 1; }
    70% { transform: scale(1); }
    100% { transform: scale(1); }
  `;

  return (
    <Container component="main" maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <Typography variant="h4" mb={3} fontWeight={500}>
              {t("Checkout.Label")}
            </Typography>
            {cart.length === 0 ? (
              <Typography variant="body1">{t("Checkout.EmptyCart")}</Typography>
            ) : (
              <CartList
                cart={cart}
                handleRemoveFromCart={handleRemoveFromCart}
                t={t}
              />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
            <OrderDetails
              cart={cart}
              t={t}
              applyAssembly={applyAssembly}
              handleToggleAssembly={handleToggleAssembly}
              userId={userDetails.user_id}
            />

            <Collapse in={orderError}>
              <Box mt={2}>
                <Alert severity="error">{errorMessage}</Alert>
              </Box>
            </Collapse>

            <Button
              variant="contained"
              fullWidth
              onClick={handlePlaceOrder}
              disabled={loading || orderPlaced}
              sx={{
                minHeight: "50px",
                position: "relative",
                backgroundColor: orderError
                  ? "error.main"
                  : orderPlaced
                  ? "success.main"
                  : "primary.main", // Red background on error
                color: "white",
                "&:hover": {
                  backgroundColor: orderError
                    ? "error.dark"
                    : orderPlaced
                    ? "success.dark"
                    : "primary.dark", // Darker red on hover when there's an error
                },
                "&.Mui-disabled": {
                  backgroundColor: orderPlaced
                    ? "success.main"
                    : "primary.main",
                  color: "white",
                  opacity: 1,
                },
              }}
            >
              {animationStage === "loading" ? (
                <CircularProgress
                  size={35}
                  sx={{
                    color: "white",
                    transition: "transform 0.5s ease",
                    transform:
                      animationStage === "shrinking"
                        ? "scale(0.1)"
                        : "scale(1)",
                  }}
                />
              ) : animationStage === "success" ? (
                <Check
                  sx={{
                    fontSize: 37,
                    animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                  }}
                />
              ) : orderError ? (
                <ClearRounded
                  sx={{
                    fontSize: 37,
                    animation: `${bounceAnimation} 0.5s ease-in-out forwards`,
                  }}
                />
              ) : (
                <Typography
                  fontWeight={500}
                  sx={{
                    transition: "transform 0.5s ease, opacity 0.5s ease",
                    transform:
                      animationStage === "shrinking"
                        ? "scale(0.1)"
                        : "scale(1)",
                    opacity: animationStage === "shrinking" ? 0 : 1,
                  }}
                >
                  {t("Checkout.PlaceOrder")}
                </Typography>
              )}
            </Button>
            <TextField
              label={t("Checkout.NotesLabel")}
              multiline
              maxRows={10}
              fullWidth
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder={t("Checkout.NotesPlaceholder")}
              sx={{ marginY: 3 }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <CheckoutForm
              initialFormFields={formFields}
              initialErrors={errors}
              isLoaded={isLoaded}
              t={t}
              showForm={showForm}
              setRememberDetails={setRememberDetails}
              onFormChange={(updatedFields) => setFormFields(updatedFields)}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CartPage;
