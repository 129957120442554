import React, { useState, useEffect, useRef } from "react";
import {
  TextField,
  Divider,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  Collapse,
} from "@mui/material";
import { StandaloneSearchBox } from "@react-google-maps/api";

const CheckoutForm = ({
  initialFormFields,
  initialErrors,
  isLoaded,
  t,
  showForm,
  rememberDetails,
  setRememberDetails,
  onFormChange,
}) => {
  const [formFields, setFormFields] = useState(initialFormFields);
  const [errors, setErrors] = useState(initialErrors);
  const inputRef = useRef(null);

  useEffect(() => {
    setFormFields(initialFormFields);
  }, [initialFormFields]);

  useEffect(() => {
    setErrors(initialErrors);
  }, [initialErrors]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    const updatedForm = { ...formFields, [name]: value };
    setFormFields(updatedForm);
    setErrors((prev) => ({ ...prev, [name]: "" }));
    if (onFormChange) {
      onFormChange(updatedForm);
    }
  };

  const handleOnPlacesChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const addressComponents = place.address_components;
      const getComponent = (components, type, useShortName = false) => {
        const component = components.find((comp) => comp.types.includes(type));
        return component
          ? useShortName
            ? component.short_name
            : component.long_name
          : "";
      };

      const updatedFields = {
        address:
          getComponent(addressComponents, "route") +
          " " +
          getComponent(addressComponents, "street_number"),
        city:
          getComponent(addressComponents, "locality") ||
          getComponent(addressComponents, "postal_town"),
        postal_code: getComponent(addressComponents, "postal_code"),
        country: getComponent(addressComponents, "country"),
      };

      const newForm = { ...formFields, ...updatedFields };
      setFormFields(newForm);
      if (onFormChange) {
        onFormChange(newForm);
      }
      setErrors((prev) => ({
        ...prev,
        address: "",
        city: "",
        postal_code: "",
        country: "",
      }));
    }
  };

  return (
    <Collapse in={showForm}>
      <Box mt={3}>
        <Divider sx={{ my: 1 }} />
        <Typography variant="body1" fontWeight={500} mb={2}>
          {t("Checkout.MissingDetails")}
        </Typography>
        <TextField
          label={t("Dashboard.MyProfile.FirstName")}
          name="firstname"
          fullWidth
          value={formFields.firstname}
          onChange={handleFieldChange}
          error={!!errors.firstname}
          helperText={errors.firstname}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label={t("Dashboard.MyProfile.LastName")}
          name="lastname"
          fullWidth
          value={formFields.lastname}
          onChange={handleFieldChange}
          error={!!errors.lastname}
          helperText={errors.lastname}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label={t("Dashboard.MyProfile.Company")}
          name="company_name"
          fullWidth
          value={formFields.company_name}
          onChange={handleFieldChange}
          error={!!errors.company_name}
          helperText={errors.company_name}
          sx={{ mb: 2 }}
        />
        {isLoaded && (
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handleOnPlacesChanged}
          >
            <TextField
              label={t("Dashboard.MyProfile.Address")}
              name="address"
              fullWidth
              value={formFields.address}
              onChange={handleFieldChange}
              error={!!errors.address}
              helperText={errors.address}
              required
              sx={{ mb: 2 }}
            />
          </StandaloneSearchBox>
        )}
        <TextField
          label={t("Dashboard.MyProfile.City")}
          name="city"
          fullWidth
          value={formFields.city}
          onChange={handleFieldChange}
          error={!!errors.city}
          helperText={errors.city}
          required
          sx={{ mb: 2 }}
        />
        <TextField
          label={t("Dashboard.MyProfile.PostalCode")}
          name="postal_code"
          fullWidth
          value={formFields.postal_code}
          onChange={handleFieldChange}
          error={!!errors.postal_code}
          helperText={errors.postal_code}
          required
          sx={{ mb: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberDetails}
              onChange={(e) => setRememberDetails(e.target.checked)}
            />
          }
          label={t("Checkout.RememberDetails")}
        />
      </Box>
    </Collapse>
  );
};

export default CheckoutForm;
