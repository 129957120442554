import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Grid,
  Typography,
  IconButton,
  Paper,
  CardMedia,
  Avatar,
  Tooltip,
  Box,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ColorThief from "colorthief";
import { t } from "i18next";

function MotifCard({ motif, showUserDetails, inCart }) {
  const theme = useTheme();
  const [activeColor, setActiveColor] = useState("white");
  const navigate = useNavigate(); // Initialize navigate function
  const imgRef = useRef(null);
  const [imageError, setImageError] = useState(false);

  const handleColorChange = (color) => {
    setActiveColor((prevColor) => (prevColor === color ? "grey" : color));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "d MMM yyyy");
  };

  const handleCardClick = () => {
    if (showUserDetails) {
      navigate(`/dashboard/motif/${motif.id}`, {
        state: { from: "admin-motif" },
      });
    } else {
      navigate(`/dashboard/motif/${motif.id}`);
    }
  };

  const handleImageError = (e) => {
    setImageError(true);
  };

  const handleImageLoad = () => {
    const colorThief = new ColorThief();
    const imgElement = imgRef.current;

    if (imgElement && imgElement.complete) {
      try {
        imgElement.crossOrigin = "Anonymous";

        const dominantColor = colorThief.getColor(imgElement);
        const [r, g, b] = dominantColor;

        if (r > 200 && g > 200 && b > 200) {
          setActiveColor("black");
        } else {
          setActiveColor("white");
        }
      } catch (error) {
        setActiveColor("black");
      }
    }
  };

  useEffect(() => {
    if (imgRef.current && !imageError) {
      handleImageLoad();
    }
  }, [motif.imageUrl, imageError]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} key={motif.id}>
      <Card
        elevation={0}
        sx={{
          position: "relative",
          overflow: "hidden",
          backgroundColor:
            activeColor === "black"
              ? theme.palette.background.dark
              : activeColor === "white"
              ? theme.palette.background.paper
              : grey[500],
          display: "flex",
          transition: "background-color 0.3s ease, transform 0.1s ease",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 1.3,
          minHeight: 180,
          cursor: "pointer",
          "&:hover": {
            boxShadow: theme.shadows[60],
            transform: "scale(1.05)",
          },
        }}
        onClick={handleCardClick}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "60%", // Adjust as needed to control size
          }}
        >
          {!imageError ? (
            <CardMedia
              component="img"
              image={motif.imageUrl}
              alt={motif.name}
              onError={handleImageError}
              onLoad={handleImageLoad}
              ref={imgRef}
              crossOrigin="Anonymous"
              sx={{
                maxWidth: "90%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <Tooltip title="Cannot load image due to unsupported file format">
              <ImageNotSupportedIcon
                sx={{
                  fontSize: 80,
                  color: theme.palette.text.secondary,
                }}
              />
            </Tooltip>
          )}
        </Box>

        <Grid
          zIndex={2}
          container
          justifyContent="space-between"
          alignItems="start"
        >
          <Grid item sm={4}>
            <Tooltip
              title={
                !motif.active
                  ? t("Article.Inactive")
                  : motif.reserved
                  ? t("Article.Reserved")
                  : inCart
                  ? t("Article.InCart")
                  : ""
              }
              arrow
            >
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: inCart
                    ? theme.palette.primary.main
                    : motif.active
                    ? theme.palette.secondary.main
                    : theme.palette.error.main,
                  textAlign: "center",
                  transition: "background-color 0.3s ease",
                }}
              >
                <Typography
                  variant="subtitle2"
                  color={!motif.active || inCart ? "white" : "text.main"}
                  sx={{ fontWeight: "500" }}
                >
                  {motif.article_number}
                </Typography>
              </Paper>
            </Tooltip>

            <Typography
              variant="caption"
              color="text.secondary"
              noWrap
              sx={{
                fontWeight: "400",
                color: activeColor === "black" ? grey[400] : "text.secondary",
                transition: "color 0.3s ease",
              }}
            >
              {formatDate(motif.createdAt)}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleColorChange("white");
              }}
              sx={{
                color: theme.palette.background.paper,
                border:
                  activeColor === "white"
                    ? `2px solid ${theme.palette.secondary.main}`
                    : "none",
                mx: 0.2,
                p: 0,
                transition: "border-color 0.3s ease",
              }}
            >
              <CircleIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                handleColorChange("black");
              }}
              sx={{
                color: theme.palette.background.dark,
                border:
                  activeColor === "black"
                    ? `2px solid ${theme.palette.secondary.main}`
                    : "none",
                mx: 0.2,
                p: 0,
                transition: "border-color 0.3s ease",
              }}
            >
              <CircleIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          zIndex={2}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="start"
          sx={{ mt: 1 }}
        >
          <Typography
            variant="caption"
            fontSize={11}
            sx={{
              fontWeight: "400",
              color: activeColor === "black" ? grey[400] : "text.secondary",
              mb: -1,
              transition: "color 0.3s ease",
            }}
          >
            {motif.width}mm x {motif.height}mm
          </Typography>
          <Typography
            variant="h6"
            noWrap
            color={activeColor === "black" ? "common.white" : "text.primary"}
            fontSize={14}
            sx={{
              fontWeight: "500",
              mb: -1,
              transition: "color 0.3s ease",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "85%",
            }}
          >
            {motif.name}
          </Typography>

          {showUserDetails && (
            <Tooltip
              title={
                <React.Fragment>
                  <Typography fontWeight={500}>
                    {motif.User.company_name}
                  </Typography>
                  {motif.User.firstname} {motif.User.lastname}
                  <Typography fontSize={9}>{motif.User.email}</Typography>
                </React.Fragment>
              }
            >
              <Avatar
                src={motif.User.avatar}
                variant="rounded"
                style={{
                  width: 30,
                  height: 30,
                  position: "absolute",
                  bottom: 10,
                  right: 10,
                }}
              >
                {motif.User.firstname.charAt(0)}
              </Avatar>
            </Tooltip>
          )}
        </Grid>
      </Card>
    </Grid>
  );
}

export default MotifCard;
