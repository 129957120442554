import { useCallback, useState, useEffect, useMemo } from "react";
import usePriceChart from "../hooks/Price/usePriceChart";
import { useColorPricing } from "../hooks/Price/useColorPricing";
import { useDiscount } from "../hooks/Price/useDiscount";
import usePricingRules from "../hooks/Price/usePriceRule";

const usePriceCalculator = () => {
  const { priceCharts = [] } = usePriceChart();
  const { colorPricing = [] } = useColorPricing();
  const [priceInfo, setPriceInfo] = useState({});
  const { getDiscountByUserId } = useDiscount();
  const { pricingRules } = usePricingRules();

  const formatPrice = (price) => {
    if (typeof price === "number") {
      const formattedPrice = price.toFixed(2);
      return parseFloat(formattedPrice).toString();
    }
    return "N/A";
  };

  const applyVAT = (netPrice) => {
    const finalPrice = netPrice * 1.25;
    const vatAmount = netPrice * 0.25;
    return { finalPrice, vatAmount };
  };

  const applyColorPricing = useCallback(
    (pricePerMotif, colors, colorDiscount = 0) => {
      const maxColorsRule = Math.max(
        ...colorPricing.map((rule) => rule.colors)
      );
      let colorRule = colorPricing.find((rule) => rule.colors === colors);

      let colorIncreaseAmount = 0;
      let discountedColorIncrease = 0;

      if (!colorRule && colors > maxColorsRule) {
        colorRule = colorPricing.find((rule) => rule.is_default);
      }

      if (colorRule) {
        colorIncreaseAmount =
          pricePerMotif * (colorRule.percentage_increase / 100);
        discountedColorIncrease =
          colorIncreaseAmount * (1 - colorDiscount / 100);
        pricePerMotif = pricePerMotif + discountedColorIncrease;
      }
      return { pricePerMotif, colorIncreaseAmount, discountedColorIncrease };
    },
    [colorPricing]
  );

  const lumpSumPrice = useMemo(() => {
    if (!pricingRules.length) return 0;
    const rule = pricingRules.find((rule) => rule.rule_type === "lump_sum");
    return rule ? parseFloat(rule.value) : 0;
  }, [pricingRules]);

  const assemblyPrice = useMemo(() => {
    if (!pricingRules.length) return 0;
    const rule = pricingRules.find((rule) => rule.rule_type === "assembly");
    return rule ? parseFloat(rule.value) : 0;
  }, [pricingRules]);

  const calculatePrice = useCallback(
    async (
      quantity,
      width,
      height,
      colors,
      itemId = null,
      userId,
      options = {}
    ) => {
      if (!priceCharts.length || !pricingRules.length) {
        if (itemId) {
          setPriceInfo((prev) => ({
            ...prev,
            [itemId]: { total: "N/A", pricePerMotif: "N/A" },
          }));
        }
        return { pricePerMotif: "N/A", total: "N/A" };
      }
      // console.log(quantity, width, height, colors, itemId, userId, options);
      // Fetch discount for the user
      const discountResponse = await getDiscountByUserId(userId);
      const discount = discountResponse[0] || {};

      // Parse the color discount
      const colorDiscount = parseFloat(discount.color_discount) || 0;
      const lumpSumDiscountPercentage =
        parseFloat(discount.lump_sum_discount) || 0;
      const generalDiscount = parseFloat(discount.general_discount) || 0;
      const assemblyDiscountPercentage =
        parseFloat(discount.assembly_discount) || 0;

      const areaCM2 = (width / 10) * (height / 10); // Convert from mm² to cm²

      let priceRange = priceCharts.find(({ range }) => {
        const [min, max] = range.split("-").map(Number);
        return quantity >= min && quantity <= max;
      });

      if (!priceRange) {
        const minRange = priceCharts[0];
        const maxRange = priceCharts[priceCharts.length - 1];

        if (quantity < parseInt(minRange.range.split("-")[0])) {
          priceRange = minRange;
        } else if (quantity > parseInt(maxRange.range.split("-")[1])) {
          priceRange = maxRange;
        }
      }

      const sortedKeys = Object.keys(priceRange.prices).sort(
        (a, b) => parseFloat(a) - parseFloat(b)
      );
      const sizeKey =
        sortedKeys.reverse().find((key) => parseFloat(key) <= areaCM2) ||
        sortedKeys[sortedKeys.length - 1];
      let pricePerMotif = parseFloat(priceRange.prices[sizeKey]);
      const unfilteredPricePerMotif = pricePerMotif;

      // Apply color pricing and color discount
      const {
        pricePerMotif: updatedPricePerMotif,
        colorIncreaseAmount,
        discountedColorIncrease,
      } = applyColorPricing(pricePerMotif, colors, colorDiscount);

      pricePerMotif = updatedPricePerMotif;

      if (discount && generalDiscount) {
        pricePerMotif = pricePerMotif * (1 - generalDiscount / 100);
      }

      let total = pricePerMotif * quantity;

      let lumpSum = lumpSumPrice;
      if (lumpSum > 0 && lumpSumDiscountPercentage > 0) {
        const discountAmount = lumpSum * (lumpSumDiscountPercentage / 100);
        lumpSum = lumpSum - discountAmount;
      }

      // Assembly Logic
      let assemblyTotal = 0;
      if (options?.applyAssembly) {
        assemblyTotal = assemblyPrice * quantity;
        if (assemblyDiscountPercentage > 0) {
          const assemblyDiscount =
            assemblyTotal * (assemblyDiscountPercentage / 100);
          assemblyTotal -= assemblyDiscount;
        }
        total += assemblyTotal;
      }
      total += lumpSum;

      const { finalPrice: totalWithVAT, vatAmount } = applyVAT(total);

      const result = {
        assemblyPrice: formatPrice(assemblyPrice * quantity),
        assemblyTotalPost: formatPrice(assemblyTotal),
        lumpSum: formatPrice(lumpSumPrice),
        lumpSumPost: formatPrice(lumpSum),
        lumpSumDiscount: discount.lump_sum_discount || 0,
        generalDiscount: discount.general_discount || 0,
        colorDiscount: discount.color_discount || 0,
        assemblyDiscount: discount.assembly_discount || 0,
        colorIncreaseAmount: formatPrice(colorIncreaseAmount * quantity),
        colorPerIncreaseAmount: formatPrice(colorIncreaseAmount),
        discountedColorIncrease: formatPrice(
          discountedColorIncrease * quantity
        ),

        pricePerMotif: formatPrice(pricePerMotif),
        total: formatPrice(total),
        totalWOLump: formatPrice(total - lumpSum),
        rawPricePerMotif: formatPrice(unfilteredPricePerMotif),
        rawTotal: formatPrice(unfilteredPricePerMotif * quantity),

        totalWithVAT: formatPrice(totalWithVAT),
        vatAmount: formatPrice(vatAmount),
      };

      if (itemId) {
        setPriceInfo((prev) => ({
          ...prev,
          [itemId]: result,
        }));
      } else {
        setPriceInfo(result);
      }

      return result;
    },
    [
      priceCharts,
      pricingRules.length,
      getDiscountByUserId,
      applyColorPricing,
      lumpSumPrice,
      assemblyPrice,
    ]
  );

  useEffect(() => {
    // Ensure that priceCharts is not empty before iterating
    if (priceCharts && priceCharts.length > 0) {
      priceCharts.forEach((chart) => {
        if (chart.items && chart.items.length > 0) {
          chart.items.forEach((item) => {
            calculatePrice(
              item.quantity,
              item.width,
              item.height,
              item.colors,
              item.itemId
            );
          });
        }
      });
    }
  }, [priceCharts, calculatePrice]);

  return { calculatePrice, applyVAT, priceInfo };
};

export default usePriceCalculator;
