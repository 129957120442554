import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  Alert,
  AlertTitle,
  Menu,
  MenuItem,
  Skeleton,
  Tabs,
  Tab,
  Badge,
  Pagination,
} from "@mui/material";
import { Add, FilterList } from "@mui/icons-material";
import MotifCard from "./MotifCard";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CartContext } from "../../context/CartContext";

function MotifList({ fetchMotifs, showUserDetails }) {
  const { motifs, loading, error } = fetchMotifs();
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const [selectedSortApproved, setSelectedSortApproved] =
    useState("recentlyUpdated");
  const [selectedSortUnapproved, setSelectedSortUnapproved] =
    useState("mostRecent");
  const [sortedMotifs, setSortedMotifs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { cart } = useContext(CartContext);
  const [tabIndex, setTabIndex] = useState(location.state?.tabIndex || 0);
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Pagination state for motifs
  const [page, setPage] = useState(1);
  const itemsPerPage = 16; // Show 20 motifs per page

  const handleSort = useCallback(
    (type, isInitial = false) => {
      let sorted;
      switch (type) {
        case "mostRecent":
          sorted = [...motifs].sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          break;
        case "oldest":
          sorted = [...motifs].sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          );
          break;
        case "recentlyUpdated":
          sorted = [...motifs].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );
          break;
        default:
          sorted = [...motifs];
          break;
      }
      setSortedMotifs(sorted);

      if (tabIndex === 0) {
        setSelectedSortUnapproved(type);
      } else if (tabIndex === 1) {
        setSelectedSortApproved(type);
      } else {
        setSelectedSortApproved(type);
      }

      if (!isInitial) {
        handleCloseMenu();
      }
    },
    [motifs, tabIndex]
  );

  useEffect(() => {
    if (motifs.length) {
      if (tabIndex === 0) {
        handleSort(selectedSortUnapproved, true);
      } else if (tabIndex === 1) {
        handleSort(selectedSortApproved, true);
      } else {
        handleSort(selectedSortApproved, true);
      }
    }
  }, [
    motifs,
    tabIndex,
    selectedSortApproved,
    selectedSortUnapproved,
    handleSort,
  ]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const isInCart = (motifId) => {
    return cart.some((item) => item.id === motifId);
  };

  // Filter motifs based on search term
  const filteredMotifs = sortedMotifs.filter(
    (motif) =>
      motif.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      motif.article_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const approvedMotifs = filteredMotifs.filter(
    (motif) => motif.approved && !motif.reserved
  );
  const waitingForApprovalMotifs = filteredMotifs.filter(
    (motif) => !motif.approved && !motif.reserved
  );
  const reservedMotifs = filteredMotifs.filter((motif) => motif.reserved);

  // Pagination: Calculate the motifs to display for the current page.
  const currentMotifs = (() => {
    let motifsToPaginate;
    if (tabIndex === 0) {
      motifsToPaginate = waitingForApprovalMotifs;
    } else if (tabIndex === 1) {
      motifsToPaginate = approvedMotifs;
    } else {
      motifsToPaginate = reservedMotifs;
    }
    const startIndex = (page - 1) * itemsPerPage;
    return motifsToPaginate.slice(startIndex, startIndex + itemsPerPage);
  })();

  return (
    <Box>
      <Box display="flex" alignItems="center" marginBottom={1}>
        <TextField
          sx={{ mr: 1, flex: 1 }} // flex:1 makes it take the remaining space
          placeholder={t("Dashboard.Motif.Search")}
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          variant="outlined"
        />
        <IconButton
          size="large"
          onClick={handleOpenMenu}
          sx={{
            backgroundColor: "primary.main",
            color: "common.white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            mr: 1,
          }}
        >
          <FilterList fontSize="inherit" />
        </IconButton>
        <IconButton
          size="large"
          onClick={() => navigate("/dashboard/create-motif")}
          sx={{
            backgroundColor: "primary.main",
            color: "common.white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          <Add fontSize="inherit" />
        </IconButton>

        <Menu
          disableScrollLock
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <MenuItem
            onClick={() => handleSort("mostRecent")}
            sx={{
              transition: "background-color 0.15s ease",
              backgroundColor:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "mostRecent"
                  ? "primary.main"
                  : "inherit",
              color:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "mostRecent"
                  ? "white"
                  : "inherit",
              "&:hover": {
                backgroundColor:
                  (tabIndex === 0
                    ? selectedSortUnapproved
                    : selectedSortApproved) === "mostRecent"
                    ? "primary.main"
                    : "lightgrey",
              },
            }}
          >
            {t("Dashboard.Motif.Sort.Newest")}
          </MenuItem>
          <MenuItem
            onClick={() => handleSort("oldest")}
            sx={{
              transition: "background-color 0.15s ease",
              backgroundColor:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "oldest"
                  ? "primary.main"
                  : "inherit",
              color:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "oldest"
                  ? "white"
                  : "inherit",
              "&:hover": {
                backgroundColor:
                  (tabIndex === 0
                    ? selectedSortUnapproved
                    : selectedSortApproved) === "oldest"
                    ? "primary.main"
                    : "lightgrey",
              },
            }}
          >
            {t("Dashboard.Motif.Sort.Oldest")}
          </MenuItem>
          <MenuItem
            onClick={() => handleSort("recentlyUpdated")}
            sx={{
              transition: "background-color 0.15s ease",
              backgroundColor:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "recentlyUpdated"
                  ? "primary.main"
                  : "inherit",
              color:
                (tabIndex === 0
                  ? selectedSortUnapproved
                  : selectedSortApproved) === "recentlyUpdated"
                  ? "white"
                  : "inherit",
              "&:hover": {
                backgroundColor:
                  (tabIndex === 0
                    ? selectedSortUnapproved
                    : selectedSortApproved) === "recentlyUpdated"
                    ? "primary.main"
                    : "lightgrey",
              },
            }}
          >
            {t("Dashboard.Motif.Sort.Recent")}
          </MenuItem>
        </Menu>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: 2 }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="motif categories"
        >
          <Tab
            label={
              <Badge
                badgeContent={waitingForApprovalMotifs.length}
                color="secondary"
                sx={{ padding: "0px 10px" }}
              >
                {t("Dashboard.Motif.CategoryWaiting")}
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                badgeContent={approvedMotifs.length}
                color="secondary"
                sx={{ padding: "0px 10px" }}
              >
                {t("Dashboard.Motif.CategoryApproved")}
              </Badge>
            }
          />
          <Tab
            label={
              <Badge
                badgeContent={reservedMotifs.length}
                color="primary"
                sx={{ padding: "0px 10px" }}
              >
                {t("Dashboard.Motif.CategoryReserved")}
              </Badge>
            }
          />
        </Tabs>
      </Box>

      {error ? (
        <Alert severity="error">
          <AlertTitle>{t("Error")}</AlertTitle>
          {error}
        </Alert>
      ) : loading ? (
        <Grid container spacing={2}>
          {[...Array(12)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Skeleton variant="rectangular" sx={{ minHeight: 180 }} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          <Grid container spacing={2}>
            {(tabIndex === 0
              ? waitingForApprovalMotifs
              : tabIndex === 1
              ? approvedMotifs
              : reservedMotifs
            )
              .slice((page - 1) * itemsPerPage, page * itemsPerPage)
              .map((motif) => (
                <MotifCard
                  key={motif.id}
                  motif={motif}
                  showUserDetails={showUserDetails}
                  inCart={isInCart(motif.id)}
                />
              ))}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Pagination
              count={Math.ceil(
                (tabIndex === 0
                  ? waitingForApprovalMotifs.length
                  : tabIndex === 1
                  ? approvedMotifs.length
                  : reservedMotifs.length) / itemsPerPage
              )}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
            />
          </Box>
        </>
      )}
    </Box>
  );
}

export default MotifList;
