import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GppBad } from "@mui/icons-material";


function UnderMaintenance() {
  const { t } = useTranslation();
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 4,
        textAlign: "center",
        margin: "auto",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <GppBad sx={{ fontSize: 60, color: "error.main", mb: 2 }} />
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 2 }}>
          {t("Dashboard.UnderMaintenance.Title")}
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
          {t("Dashboard.UnderMaintenance.SubTitle")}
        </Typography>
      </Box>
    </Paper>
  );
}

export default UnderMaintenance;
