import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import AOS from "aos";
import "aos/dist/aos.css";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./styles/theme.jsx";

import Home from "./pages/Home";
import LoginPage from "./pages/Auth/Login/index.jsx";
import SignupPage from "./pages/Auth/SignUp/index.jsx";
import Header from "./components/layout/header/header";
import Footer from "./components/layout/footer/footer.jsx";
// import { CartProvider } from "./context/CartContext.js";

import { NotFound, NotFoundDash } from "./pages/NotFound";
import Dashboard from "./pages/Dashboard/";
import Calculator from "./pages/Dashboard/Calculator/index.jsx";
import SettingsPage from "./pages/Dashboard/Settings/index.jsx";
import MyMotiv from "./pages/Dashboard/Motiv/index.jsx";
import CreateMotif from "./pages/Dashboard/Motiv/createMotif.jsx";
import MotifPage from "./pages/Dashboard/Motiv/motifPage.jsx";
import AdminMyMotif from "./pages/Dashboard/Admin/Motiv/index.jsx";
import AdminPage from "./pages/Dashboard/Admin/index.jsx";
import AdminCreateUser from "./pages/Dashboard/Admin/CreateUser/index.jsx";
import PriceChart from "./pages/Dashboard/Admin/PriceChart/index.jsx";
import EditMotif from "./pages/Dashboard/Motiv/editMotif.jsx";
import CartPage from "./pages/Checkout/index.jsx";
import ProfilePage from "./pages/Dashboard/Profile/index.jsx";
import OrderHistory from "./pages/Dashboard/OrderHistory/index.jsx";
import ComingSoon from "./pages/NotFound/ComingSoon.jsx";
import OrderDetails from "./pages/Dashboard/OrderHistory/orderDetails.jsx";
import AdminOrderHistory from "./pages/Dashboard/OrderHistory/adminIndex.jsx";
import PDFViewPage from "./components/OrderPDFTemplate.jsx";
import UsersPage from "./pages/Dashboard/Admin/Users/index.jsx";
import UserRecovery from "./pages/Auth/UserRecovery/index.jsx";
import PasswordResetPage from "./pages/Auth/UserRecovery/PassReset.jsx";
import ProtectedDashboardRoute from "./components/dashboard/ProtectedDashboardRoute.jsx";

function App() {
  useEffect(() => {
    AOS.init({
      // Global settings:
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 50,
      throttleDelay: 99,

      // Settings that can be overridden on per-element basis:
      offset: 300,
      delay: 0,
      duration: 700,
      easing: "ease-out-sine",
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Router>
        <Box display="flex" flexDirection="column" minHeight="125vh">
          <Header />
          <Box component="main" flexGrow={1}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/checkout" element={<CartPage />} />
              <Route path="/pdf" element={<PDFViewPage />} />
              <Route path="/userrecovery" element={<UserRecovery />} />
              <Route
                path="/userrecovery/:resetToken"
                element={<PasswordResetPage />}
              />

              <Route
                path="/dashboard"
                element={
                  <Dashboard />
                }
              >
                <Route
                  path="motif"
                  element={
                    <ProtectedDashboardRoute>
                      <MyMotiv />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="motif/:motifId"
                  element={
                    <ProtectedDashboardRoute>
                      <MotifPage />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="motif/:motifId/edit"
                  element={
                    <ProtectedDashboardRoute>
                      <EditMotif />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="create-motif"
                  element={
                    <ProtectedDashboardRoute>
                      <CreateMotif />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="calculator"
                  element={
                    <ProtectedDashboardRoute>
                      <Calculator />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="settings"
                  element={
                    <ProtectedDashboardRoute>
                      <SettingsPage />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="profile"
                  element={
                    <ProtectedDashboardRoute>
                      <ProfilePage />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="order-history"
                  element={
                    <ProtectedDashboardRoute>
                      <OrderHistory />
                    </ProtectedDashboardRoute>
                  }
                />
                <Route
                  path="order/:orderId"
                  element={
                    <ProtectedDashboardRoute>
                      <OrderDetails />
                    </ProtectedDashboardRoute>
                  }
                />

                <Route
                  path="admin"
                  element={
                    <ProtectedDashboardRoute>
                      <AdminPage />
                    </ProtectedDashboardRoute>
                  }
                >
                  <Route
                    path="all-motifs"
                    element={
                      <ProtectedDashboardRoute>
                        <AdminMyMotif />
                      </ProtectedDashboardRoute>
                    }
                  />
                  <Route
                    path="create-user"
                    element={
                      <ProtectedDashboardRoute>
                        <AdminCreateUser />
                      </ProtectedDashboardRoute>
                    }
                  />
                  <Route
                    path="price-chart"
                    element={
                      <ProtectedDashboardRoute>
                        <PriceChart />
                      </ProtectedDashboardRoute>
                    }
                  />
                  <Route
                    path="orders"
                    element={
                      <ProtectedDashboardRoute>
                        <AdminOrderHistory />
                      </ProtectedDashboardRoute>
                    }
                  />
                  <Route
                    path="users"
                    element={
                      <ProtectedDashboardRoute>
                        <UsersPage />
                      </ProtectedDashboardRoute>
                    }
                  />
                </Route>

                <Route path="*" element={<NotFoundDash />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
