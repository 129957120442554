import React, { useEffect, useState, useMemo } from "react";
import {
  Paper,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  TextField,
  Box,
  Menu,
  MenuItem,
  Chip,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useUser } from "../../../../hooks/useUser";
import { useDiscount } from "../../../../hooks/Price/useDiscount";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import DiscountIcon from "@mui/icons-material/Discount";
import {
  Build,
  ColorLens,
  Edit,
  LocalOffer,
  MonetizationOn,
} from "@mui/icons-material";

function UsersPage() {
  const { t } = useTranslation();
  const { users, loading, error, getAllUsers, removeUser } = useUser();
  const { discounts, getAllDiscounts, createOrUpdateDiscount } = useDiscount();
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDiscountDialog, setOpenDiscountDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [discountValues, setDiscountValues] = useState({
    color_discount: 0,
    lump_sum_discount: 0,
    general_discount: 0,
    assembly_discount: 0,
  });
  const [rowsPerPage] = useState([15, 25, 50, { value: -1, label: "All" }]);
  useEffect(() => {
    getAllUsers();
    getAllDiscounts();
  }, [getAllUsers, getAllDiscounts]);

  useEffect(() => {
    if (users) {
      setFilteredUsers(users);
    }
  }, [users]);

  const handleRemoveUser = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      removeUser(userId);
    }
  };

  const getUserDiscounts = (userId) =>
    discounts.filter((d) => d.user_id === userId);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const lower = value.toLowerCase();
    const filtered = users.filter(
      (user) =>
        (user.firstname?.toLowerCase() || "").includes(lower) ||
        (user.lastname?.toLowerCase() || "").includes(lower) ||
        (user.email?.toLowerCase() || "").includes(lower) ||
        (user.company_name?.toLowerCase() || "").includes(lower)
    );
    setFilteredUsers(filtered);
  };

  const handleOpenMenu = (e) => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleOpenDiscountDialog = (user) => {
    const userDiscounts = getUserDiscounts(user.id);
    setDiscountValues({
      color_discount:
        userDiscounts.find((d) => d.color_discount)?.color_discount || 0,
      lump_sum_discount:
        userDiscounts.find((d) => d.lump_sum_discount)?.lump_sum_discount || 0,
      general_discount:
        userDiscounts.find((d) => d.general_discount)?.general_discount || 0,
      assembly_discount:
        userDiscounts.find((d) => d.assembly_discount)?.assembly_discount || 0,
    });
    setSelectedUser(user);
    setOpenDiscountDialog(true);
  };

  const handleCloseDiscountDialog = () => {
    setOpenDiscountDialog(false);
    setSelectedUser(null);
  };

  const handleDiscountChange = (field) => (e) =>
    setDiscountValues((prev) => ({
      ...prev,
      [field]: parseFloat(e.target.value) || 0,
    }));

  const handleSubmitDiscount = async () => {
    if (!selectedUser) return;
    try {
      await createOrUpdateDiscount({
        user_id: selectedUser.id,
        ...discountValues,
      });
      handleCloseDiscountDialog();
    } catch (err) {
      console.error("Error saving discount:", err);
    }
  };

  const getRoleBadge = (role) => {
    switch (role) {
      case "admin":
        return (
          <Chip
            size="small"
            label={t("Dashboard.Roles.Admin")}
            sx={{
              backgroundColor: "#FFD700",
              fontWeight: "500",
              fontSize: 10,
              boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
            }}
          />
        );
      case "superadmin":
        return (
          <Chip
            size="small"
            label={t("Dashboard.Roles.SuperAdmin")}
            sx={{
              backgroundColor: "primary.dark",
              color: "white",
              fontWeight: "500",
              fontSize: 10,
              boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
            }}
          />
        );
      case "private_customer":
        return (
          <Chip
            size="small"
            label={t("Dashboard.Roles.PrivateCustomer")}
            sx={{
              backgroundColor: "warning.light",
              fontWeight: "500",
              fontSize: 10,
              boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
            }}
          />
        );
      default:
        return (
          <Chip
            size="small"
            label={t("Dashboard.Roles.Customer")}
            sx={{
              backgroundColor: "secondary.main",
              fontWeight: "500",
              fontSize: 10,
              boxShadow: "0px 0px 4px rgba(0,0,0,0.25)",
            }}
          />
        );
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { day: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const formatTime = (dateString) => {
    if (!dateString) return "-";
    const options = { hour: "2-digit", minute: "2-digit" };
    return new Date(dateString).toLocaleTimeString("en-GB", options);
  };

  const numericPageSizes = rowsPerPage.filter(
    (option) => typeof option === "number"
  );
  const defaultPageSize = Math.min(...numericPageSizes);

  // Define DataGrid columns
  const columns = useMemo(
    () => [
      {
        field: "name",
        headerName: t("Dashboard.Users.Label"),
        flex: 1,
        valueGetter: (_, row) => `${row.firstname || ""} ${row.lastname || ""}`,

        renderCell: (params) => (
          <Box display="flex" alignItems="center" gap={1}>
            <Avatar
              src={params.row.avatar}
              alt={params.row.firstname}
              sx={{ width: 32, height: 32 }}
            >
              {params.row.avatar ? "" : params.row.firstname.charAt(0)}
            </Avatar>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="body2">
                {params.row.firstname} {params.row.lastname}
              </Typography>
              <Typography variant="body2" color="text.secondary" fontSize={12}>
                {params.row.company_name}
              </Typography>
            </Box>
          </Box>
        ),
      },
      { field: "email", headerName: "Email", flex: 1 },
      {
        field: "last_login",
        headerName: t("Dashboard.Users.LastLogin"),
        flex: 1,
        renderCell: (params) => (
          <Tooltip title={formatTime(params.row.last_login)} arrow>
            <Typography variant="body2">
              {formatDate(params.row.last_login)}
            </Typography>
          </Tooltip>
        ),
      },
      {
        field: "accesslevel",
        headerName: t("Dashboard.CreateUser.AccessLevel.Label"),
        flex: 1,
        renderCell: (params) => getRoleBadge(params.value),
      },
      {
        field: "discounts",
        headerName: t("Dashboard.Users.Discount"),
        flex: 1,
        renderCell: (params) => {
          const userDiscounts = getUserDiscounts(params.row.id);
          const discount = userDiscounts[0];

          // If there's no discount record or all values are zero
          if (
            !discount ||
            (Number(discount.color_discount) === 0 &&
              Number(discount.lump_sum_discount) === 0 &&
              Number(discount.general_discount) === 0 &&
              Number(discount.assembly_discount) === 0)
          ) {
            return <Typography variant="body2">-</Typography>;
          }

          const formatDiscountValue = (value) => {
            const num = Number(value);
            if (isNaN(num)) return "";
            return num % 1 === 0 ? num.toFixed(0) : num.toFixed(1);
          };

          return (
            <Box display="flex" flexWrap="wrap" gap={0.75} alignItems="center">
              {Number(discount.general_discount) > 0 && (
                <Tooltip
                  title="General Discount"
                  disableInteractive
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#388e3c",
                        color: "#fff",
                        fontSize: 12,
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <LocalOffer fontSize="small" sx={{ color: "#388e3c" }} />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#388e3c"
                    >
                      {formatDiscountValue(discount.general_discount)}%
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              {Number(discount.color_discount) > 0 && (
                <Tooltip
                  title="Color Discount"
                  disableInteractive
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#00796b",
                        color: "#fff",
                        fontSize: 12,
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <ColorLens fontSize="small" sx={{ color: "#00796b" }} />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#00796b"
                    >
                      {formatDiscountValue(discount.color_discount)}%
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              {Number(discount.lump_sum_discount) > 0 && (
                <Tooltip
                  title="Lump Sum Discount"
                  disableInteractive
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#e65100",
                        color: "#fff",
                        fontSize: 12,
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <MonetizationOn
                      fontSize="small"
                      sx={{ color: "#e65100" }}
                    />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#e65100"
                    >
                      {formatDiscountValue(discount.lump_sum_discount)}%
                    </Typography>
                  </Box>
                </Tooltip>
              )}
              {Number(discount.assembly_discount) > 0 && (
                <Tooltip
                  title="Assembly Discount"
                  disableInteractive
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#8e24aa",
                        color: "#fff",
                        fontSize: 12,
                      },
                    },
                  }}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Build fontSize="small" sx={{ color: "#8e24aa" }} />
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#8e24aa"
                    >
                      {formatDiscountValue(discount.assembly_discount)}%
                    </Typography>
                  </Box>
                </Tooltip>
              )}
            </Box>
          );
        },
      },
      {
        field: "actions",
        headerName: t("Dashboard.OrderHistory.Actions") || "Actions",
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Box display="flex" gap={1}>
            <Tooltip title={t("Dashboard.Users.Edit")}>
              <IconButton>
                <Edit sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Dashboard.Users.GiveDiscount")}>
              <IconButton onClick={() => handleOpenDiscountDialog(params.row)}>
                <DiscountIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Dashboard.Users.Delete")}>
              <IconButton onClick={() => handleRemoveUser(params.row.id)}>
                <DeleteIcon sx={{ fontSize: 16 }} />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [t, handleOpenDiscountDialog, getUserDiscounts]
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <Paper elevation={3} sx={{ padding: 4, margin: "auto", borderRadius: 2 }}>
        {/* Header: Search and Filters */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <Typography variant="h4">{t("Dashboard.Users.Label")}</Typography>
          <Box display="flex" alignItems="center" gap={1}>
            <TextField
              placeholder={t("Dashboard.Users.Search")}
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
            />
            <IconButton onClick={handleOpenMenu}>
              <FilterListIcon />
            </IconButton>
            <Menu
              disableScrollLock
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>Filter Option 1</MenuItem>
              <MenuItem onClick={handleCloseMenu}>Filter Option 2</MenuItem>
            </Menu>
          </Box>
        </Box>
        <DataGrid
          rows={filteredUsers}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: defaultPageSize } },
          }}
          pageSizeOptions={rowsPerPage}
          disableSelectionOnClick
          sx={{
            "& .MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
            },
          }}
        />
      </Paper>
      {/* Discount Dialog */}
      <Dialog open={openDiscountDialog} onClose={handleCloseDiscountDialog}>
        <DialogTitle>{t("Dashboard.Users.GiveDiscount")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Dashboard.Users.AssignDiscount")}
          </DialogContentText>
          <TextField
            margin="dense"
            label={t("Dashboard.Users.ColorDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.color_discount}
            onChange={handleDiscountChange("color_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.LumpSumDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.lump_sum_discount}
            onChange={handleDiscountChange("lump_sum_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.GeneralDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.general_discount}
            onChange={handleDiscountChange("general_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            margin="dense"
            label={t("Dashboard.Users.AssemblyDiscount")}
            type="number"
            fullWidth
            variant="outlined"
            value={discountValues.assembly_discount}
            onChange={handleDiscountChange("assembly_discount")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDiscountDialog} color="secondary">
            {t("Dashboard.Users.Cancel")}
          </Button>
          <Button onClick={handleSubmitDiscount} color="primary">
            {t("Dashboard.Users.Submit")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UsersPage;
