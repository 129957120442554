import React from "react";
import {
  List,
  Card,
  CardMedia,
  Grid,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { Link } from "react-router-dom";

const CartList = ({ cart, handleRemoveFromCart, t }) => {
  return (
    <List>
      {cart.map((item) => (
        <Card key={item.id} sx={{ boxShadow: "none", marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={5} sm={4} md={3}>
              <Link
                to={`/dashboard/motif/${item.id}`}
                state={{ from: "checkout" }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <CardMedia
                  component="img"
                  image={item.imageUrl}
                  alt={item.name}
                  sx={{
                    objectFit: "contain",
                    borderRadius: 1,
                    backgroundColor: "grey",
                    padding: 1,
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={7} sm={8} md={9}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Box mb={2}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Link
                      to={`/dashboard/motif/${item.id}`}
                      state={{ from: "checkout" }}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <Typography variant="h5" fontWeight={600} noWrap>
                        {item.name}
                      </Typography>
                    </Link>
                    <Tooltip title="Remove from Cart" arrow>
                      <IconButton
                        size="small"
                        aria-label="remove"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFromCart(item.id);
                        }}
                      >
                        <ClearRoundedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Tooltip
                      title={
                        !item.active
                          ? t("Article.Inactive")
                          : item.reserved
                          ? t("Article.Reserved")
                          : ""
                      }
                      arrow
                    >
                      <Typography
                        variant="subtitle2"
                        paddingX={2}
                        color={
                          !item.active || item.reserved ? "white" : "text.main"
                        }
                        sx={{
                          fontWeight: "500",
                          backgroundColor: item.reserved
                            ? "primary.main"
                            : !item.active
                            ? "error.main"
                            : "secondary.main",
                          borderRadius: 1,
                        }}
                      >
                        {item.article_number}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Typography variant="body2" color="text.secondary" mt={1}>
                    {item.quantity}x
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.width}mm x {item.height}mm ={" "}
                    {parseFloat(
                      (((item.width / 10) * item.height) / 10).toFixed(2)
                    ).toString()}{" "}
                    cm²
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Card>
      ))}
    </List>
  );
};

export default CartList;
