import React, { useState } from "react";
import {
  Container,
  Snackbar,
  Alert,
  Box,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Link,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from "../../../hooks/useAuth";

const UserRecovery = () => {
  const [email, setEmail] = useState("");
  const [emailTouched, setEmailTouched] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { forgotPassword } = useAuth();
  const { t } = useTranslation();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (!emailTouched) setEmailTouched(true);
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    setEmailError(!emailRegex.test(e.target.value));
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (token) {
      setCaptchaError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailError || !captchaToken) {
      if (!captchaToken) setCaptchaError(true);
      return;
    }
    try {
      const result = await forgotPassword(email, captchaToken);
      if (result) {
        console.log("Reset link sent:", result.message);
        setSubmitted(true);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error requesting reset link:", error);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper
        elevation={3}
        sx={{
          padding: 6,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mx: 2,
        }}
      >
        <Typography component="h1" variant="h4" fontWeight="600">
          {t("UserRecovery.Title")}
        </Typography>
        <Typography
          component="p"
          color="text.secondary"
          fontWeight="500"
          sx={{ mt: 2 }}
        >
          {t("UserRecovery.Subtitle")}
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ mt: 3, width: "100%" }}
        >
          <TextField
            fullWidth
            required
            id="email"
            label={t("Dashboard.CreateUser.Email")}
            name="email"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            error={emailTouched && emailError}
            helperText={emailTouched && emailError && t("LogInPage.ValidEmail")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ mt: 2, mb: 2, justifyItems: "center" }}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={handleCaptchaChange}
            />
            {captchaError && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {t("UserRecovery.CaptchaError")}
              </Typography>
            )}
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {t("UserRecovery.SendLink")}
          </Button>
          <Link component={RouterLink} to="/login" variant="body2">
            {t("UserRecovery.BackToLogin")}
          </Link>
        </Box>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {submitted ? t("UserRecovery.LinkSent") : ""}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default UserRecovery;
