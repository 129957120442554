import React, { useEffect, useMemo } from "react";
import {
  List,
  Typography,
  Box,
  Divider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import usePriceCalculator from "../priceCalculator";

const OrderDetails = ({
  cart,
  t,
  applyAssembly,
  handleToggleAssembly,
  userId,
}) => {
  const { calculatePrice, applyVAT, priceInfo } = usePriceCalculator();

  useEffect(() => {
    if (cart && cart.length > 0) {
      cart.forEach((item) => {
        if (item.width && item.height) {
          calculatePrice(
            item.quantity,
            item.width,
            item.height,
            item.colors,
            item.id,
            userId,
            { applyAssembly }
          );
        }
      });
    }
  }, [cart, calculatePrice, applyAssembly, userId]);

  const netSubtotal = useMemo(() => {
    return cart.reduce((acc, item) => {
      const itemNet = priceInfo[item.id]
        ? parseFloat(priceInfo[item.id].total)
        : 0;
      return acc + itemNet;
    }, 0);
  }, [cart, priceInfo]);

  const { finalPrice: overallTotalWithVAT, vatAmount: overallVat } =
    useMemo(() => {
      return applyVAT(netSubtotal);
    }, [netSubtotal, applyVAT]);

  const renderCartItem = (item, index) => {
    const itemPriceInfo = priceInfo[item.id] || {
      pricePerMotif: t("Checkout.Calculating"),
      total: t("Checkout.Calculating"),
    };
    return (
      <Box key={item.id}>
        <Typography variant="body1" fontWeight={500}>
          {index + 1}. {item.name}
        </Typography>
        <Typography variant="body2" fontSize={16} color="text.secondary">
          {itemPriceInfo.totalWOLump} kr
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {t("Checkout.LumpSum")}: + {itemPriceInfo.lumpSumPost} kr
        </Typography>
        {item.colors > 1 && (
          <Typography variant="body2" color="text.secondary">
            {t("Dashboard.PriceCalculator.ColorImpact")}: +{" "}
            {itemPriceInfo.discountedColorIncrease} kr
          </Typography>
        )}
        {applyAssembly && (
          <Typography variant="body2" color="text.secondary">
            {t("Checkout.Assembly")}: + {itemPriceInfo.assemblyTotalPost} kr
          </Typography>
        )}
        <Divider sx={{ my: 1 }} />
      </Box>
    );
  };

  return (
    <>
      <Typography variant="h5" fontWeight={500} gutterBottom>
        {t("Checkout.OrderDetails")}
      </Typography>
      <List>{cart.map(renderCartItem)}</List>
      <FormControlLabel
        control={
          <Checkbox checked={applyAssembly} onChange={handleToggleAssembly} />
        }
        label={t("Checkout.IncludeAssembly")}
      />
      <Box mb={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Box>
            <Typography variant="body1" fontWeight={500}>
              {t("Checkout.GrandTotal")}
            </Typography>
            <Typography variant="body1" fontSize={14} color="text.secondary">
              {t("Checkout.VATIncluded")}
            </Typography>
          </Box>
          <Typography variant="body1" fontWeight={500}>
            {parseFloat(overallTotalWithVAT.toFixed(2)).toString()} kr
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="text.secondary">
            {t("Checkout.VAT")}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            + {parseFloat(overallVat.toFixed(2)).toString()} kr
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="text.secondary">
            {t("Checkout.GrandTotal")} {t("Checkout.VATExcluded")}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {parseFloat(netSubtotal.toFixed(2)).toString()} kr
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default OrderDetails;
