import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend) // Load translations using http (default public/assets/locals/{{lng}}/{{ns}}.json)
  //   .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: "no", // Default language
    debug: false, // Enable console logs for debugging
    backend: {
      loadPath: "/lang/{{lng}}.json", // Path to the language JSON files
    },

    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

export default i18n;
