import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

// Define the centralized mapping once.
const statusMappings = {
  pending: "Dashboard.OrderHistory.Status.Pending",
  received: "Dashboard.OrderHistory.Status.Received",
  "in-progress": "Dashboard.OrderHistory.Status.InProgress",
  completed: "Dashboard.OrderHistory.Status.Completed",
  shipped: "Dashboard.OrderHistory.Status.Shipped",
  invoiced: "Dashboard.OrderHistory.Status.Invoiced",
  unknown: "Dashboard.OrderHistory.Status.Unknown",
};

const StatusBadge = ({ status, variant = "default", sx = {}, ...props }) => {
  const { t } = useTranslation();
  const labelKey = statusMappings[status] || statusMappings.unknown;

  // Determine badge color based on status.
  let color;
  switch (status) {
    case "pending":
      color = "error";
      break;
    case "received":
      color = "warning";
      break;
    case "in-progress":
      color = "info";
      break;
    case "completed":
      color = "success";
      break;
    case "shipped":
      color = "secondary";
      break;
    case "invoiced":
      // Use "success" as the key, then override its shade in the sx below.
      color = "success";
      break;
    default:
      color = "error";
  }

  // Render differently for the "menu" variant.
  if (variant === "menu") {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor:
            status === "invoiced"
              ? theme.palette.success.dark
              : theme.palette[color].main,
          width: 10,
          height: 10,
          borderRadius: "50%",
          mr: 2,
          ...sx,
        })}
        {...props}
      />
    );
  }

  // Render full badge.
  return (
    <Box
      sx={(theme) => ({
        display: "inline-block",
        px: 1,
        py: 0.5,
        bgcolor:
          status === "invoiced"
            ? theme.palette.success.dark
            : theme.palette[color].main,
        borderRadius: 1,
        color:
          status === "invoiced"
            ? theme.palette.getContrastText(theme.palette.success.dark)
            : theme.palette.getContrastText(theme.palette[color].main),
        ...sx,
      })}
      {...props}
    >
      {t(labelKey)}
    </Box>
  );
};

// Expose static properties so they can be reused elsewhere.
StatusBadge.statuses = statusMappings;
StatusBadge.keys = Object.keys(statusMappings);

export default StatusBadge;
