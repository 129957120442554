import React, { useState } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import "react-image-crop/dist/ReactCrop.css";
import AvatarUpload from "../../../../components/admin/AvatarUpload";
import { useAuth } from "../../../../hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Paper,
  TextField,
  Button,
  Typography,
  Box,
  Avatar,
  Grid,
  Badge,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useTranslation } from "react-i18next";

function AdminCreateUser() {
  const { t } = useTranslation();
  const { registerUser } = useAuth();
  const [modalOpen, setModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: "", // Initialize confirmPassword here
    avatar: "",
    accesslevel: "customer",
  });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false); // Add state for success
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update user details for any input field
    setUserDetails((prev) => ({
      ...prev,
      [name]: value, // Update the corresponding field in userDetails
    }));

    // Email validation
    if (name === "email" && !validateEmail(value)) {
      setErrors((prev) => ({
        ...prev,
        email: "Invalid email format",
      }));
    } else if (name === "email") {
      setErrors((prev) => ({
        ...prev,
        email: "",
      }));
    }

    // Confirm password matching
    if (name === "confirmPassword" && userDetails.password !== value) {
      setErrors((prev) => ({
        ...prev,
        password: "Passwords do not match",
      }));
    } else if (name === "confirmPassword") {
      setErrors((prev) => ({
        ...prev,
        password: "",
      }));
    }
  };

  const handleAvatarSave = (canvas, imgName) => {
    const avatar = canvas.toDataURL();
    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("Canvas is empty");
        return;
      }

      const file = new File([blob], imgName, {
        type: "image/png",
        lastModified: new Date(),
      });

      setUserDetails((prev) => ({
        ...prev,
        avatar: file,
        avatarPreview: avatar,
      }));

      setModalOpen(false);
    }, "image/png");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitSuccess(false); // Reset success state on submit
    setSubmitError(""); // Reset error state on submit

    if (
      !errors.email &&
      !errors.password &&
      userDetails.password === userDetails.confirmPassword
    ) {
      try {
        await registerUser(userDetails);
        setSubmitSuccess(true); // Set success state
        setUserDetails({
          // Reset form on success
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          confirmPassword: "",
          avatar: "",
          accesslevel: "customer",
        });
      } catch (error) {
        setSubmitError(
          error.message || t("Dashboard.CreateUser.ErrorFailUser")
        ); // Set error state with the actual error message
      }
    } else {
      setSubmitError(t("Dashboard.CreateUser.Error"));
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{ padding: 3, display: "flex", flexDirection: "column" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t("Dashboard.CreateUser.CreateUser")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="dense"
              required
              fullWidth
              label={t("Dashboard.CreateUser.FirstName")}
              placeholder="John"
              name="firstname"
              value={userDetails.firstname}
              onChange={handleChange}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              label={t("Dashboard.CreateUser.LastName")}
              placeholder="Doe"
              name="lastname"
              value={userDetails.lastname}
              onChange={handleChange}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              error={!!errors.email}
              helperText={errors.email}
              label={t("Dashboard.CreateUser.Email")}
              placeholder={t("Dashboard.CreateUser.EmailExample")}
              name="email"
              value={userDetails.email}
              onChange={handleChange}
            />
            <TextField
              margin="dense"
              required
              fullWidth
              label={t("Dashboard.CreateUser.Password")}
              type={showPassword ? "text" : "password"}
              name="password"
              value={userDetails.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin="dense"
              required
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
              label={t("Dashboard.CreateUser.ConfirmPassword")}
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={userDetails.confirmPassword}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth margin="dense">
              <InputLabel id="access-level-label">
                {t("Dashboard.CreateUser.AccessLevel.Label")}
              </InputLabel>
              <Select
                labelId="access-level-label"
                id="accesslevel"
                defaultValue={"customer"}
                value={userDetails.accesslevel}
                label={t("Dashboard.CreateUser.AccessLevel.Label")}
                onChange={(e) =>
                  setUserDetails({
                    ...userDetails,
                    accesslevel: e.target.value,
                  })
                }
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="customer">
                  {t("Dashboard.Roles.Customer")}
                </MenuItem>
                <MenuItem value="private_customer">
                  {t("Dashboard.Roles.PrivateCustomer")}
                </MenuItem>
              </Select>
            </FormControl>
            {submitError && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {submitError}
              </Alert>
            )}
            {submitSuccess && (
              <Alert severity="success" sx={{ mt: 2 }}>
                {t("Dashboard.CreateUser.Success")}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Dashboard.CreateUser.Submit")}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Badge
            onClick={() => setModalOpen(true)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            overlap="rectangular"
            badgeContent={
              <PhotoCamera
                sx={{
                  color: "white",
                  padding: 0.5,
                  backgroundColor: "primary.main",
                  borderRadius: "100%",
                }}
              />
            }
          >
            <Avatar
              variant="rounded"
              src={userDetails.avatarPreview}
              alt="Avatar Preview"
              sx={{
                width: 200,
                height: 200,
                cursor: "pointer",
                border: "2px dashed",
                borderColor: "primary.main",
              }}
            />
          </Badge>
          <Typography variant="h6">
            {userDetails.firstname || t("Dashboard.CreateUser.FirstName")}{" "}
            {userDetails.lastname || t("Dashboard.CreateUser.LastName")}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userDetails.email || t("Dashboard.CreateUser.EmailExample")}
          </Typography>
        </Grid>
      </Grid>
      {modalOpen && (
        <AvatarUpload
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleAvatarSave}
        />
      )}
    </Paper>
  );
}

export default AdminCreateUser;
