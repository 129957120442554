import React, { useRef, useState } from "react";
import { Modal, Box, Button, Typography, Grid, Alert } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const MIN_DIMENSION = 150;
const ASPECT_RATIO = 1;

function AvatarUpload({ open, onClose, onSave }) {
  const [preview, setPreview] = useState(null);
  const [imgName, setImgName] = useState("");
  const [error, setError] = useState("");
  const [crop, setCrop] = useState();
  const imgRef = useRef(null);
  const canvasRef = useRef(null);

  const handleAvatarChange = (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const imageElement = new Image();
      const imageURL = reader.result || "";
      imageElement.src = imageURL;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalHeight < MIN_DIMENSION || naturalWidth < MIN_DIMENSION) {
          setError("Image must be at least 150px wide and 150px tall");
          return setPreview(null);
        }
      });

      setImgName(file.name);
      setPreview(imageURL);
    };
    reader.readAsDataURL(file);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercentage = (MIN_DIMENSION / width) * 100;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercentage,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const setCanvasPreview = (image, canvas, crop) => {
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Failed to get canvas context");
    }

    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = "high";
    ctx.save();

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;

    ctx.translate(-cropX, -cropY);
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    );

    ctx.restore();
  };

  const handleConfirm = () => {
    setCanvasPreview(
      imgRef.current,
      canvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    const canvas = canvasRef.current;
    onSave(canvas, imgName);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "50%" },
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          overflow: "auto",
          maxHeight: "90vh",
        }}
      >
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleAvatarChange}
        />
        <label htmlFor="raised-button-file">
          <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Grid item xs={12} sm={4}>
              <Button
                size="small"
                variant="contained"
                component="span"
                startIcon={<PhotoCamera />}
              >
                Upload Avatar
              </Button>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="body2" color="text.secondary" noWrap>
                {preview ? imgName : "No file chosen"}
              </Typography>
            </Grid>
          </Grid>
        </label>
        {error && <Alert severity="error">{error}</Alert>}
        {preview && (
          <ReactCrop
            crop={crop}
            keepSelection
            aspect={ASPECT_RATIO}
            minWidth={MIN_DIMENSION}
            onChange={(pixelCrop, percentageCrop) => setCrop(percentageCrop)}
          >
            <img
              ref={imgRef}
              src={preview}
              alt="Upload"
              onLoad={onImageLoad}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </ReactCrop>
        )}
        {crop && (
          <canvas
            ref={canvasRef}
            style={{
              display: "none",
              border: "1px solid black",
              objectFit: "contain",
              width: 150,
              height: 150,
            }}
          />
        )}
        {preview && (
          <Button
            variant="contained"
            onClick={handleConfirm}
            sx={{ mt: 2, display: preview ? "block" : "none" }}
          >
            Confirm
          </Button>
        )}

        {/* Inline CSS to override the crop selection's border-radius */}
        <style jsx>{`
          .ReactCrop__crop-selection {
            border-radius: 12px !important;
          }
        `}</style>
      </Box>
    </Modal>
  );
}

export default AvatarUpload;
