import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Paper,
  Typography,
  CircularProgress,
  Button,
  Grid,
  TextField,
  MenuItem,
  Box,
  Alert,
  IconButton,
  Tooltip,
  Snackbar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ApproveIcon from "@mui/icons-material/CheckCircleOutline";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { useMotifs } from "../../../hooks/useMotifs";
import { useTheme } from "@emotion/react";
import usePriceCalculator from "../../../components/priceCalculator";
import { useAuth } from "../../../hooks/useAuth";
import { alpha } from "@mui/material/styles";
import { useAdmin } from "../../../hooks/useAdmin";
import { CartContext } from "../../../context/CartContext";
import { useTranslation } from "react-i18next";
import { PriorityHigh } from "@mui/icons-material";

const MotifPage = () => {
  const { userDetails } = useAuth();
  const { approveMotif, activateMotif } = useAdmin();
  const theme = useTheme();
  const { motifId } = useParams();
  const navigate = useNavigate();
  const { getMotifById } = useMotifs();
  const { calculatePrice, priceInfo } = usePriceCalculator();
  const [motif, setMotif] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [quantity, setQuantity] = useState(15);
  const [quantityWarning, setQuantityWarning] = useState(false);
  const [successMessage, setSuccessMessage] = useState(""); // State for success message
  const [alertOpen, setAlertOpen] = useState(false); // State for snackbar alert
  const { cart, handleAddToCart } = useContext(CartContext); // Use Cart Context with addToCart function
  const itemPriceInfo = priceInfo[motifId] || { total: "0" };
  const [imageError, setImageError] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  const isInCart = cart.some((cartItem) => cartItem.id === parseFloat(motifId));

  useEffect(() => {
    const fetchMotif = async () => {
      setLoading(true);
      try {
        const fetchedMotif = await getMotifById(motifId);
        setMotif(fetchedMotif);
        calculatePrice(
          15,
          fetchedMotif.width,
          fetchedMotif.height,
          fetchedMotif.colors,
          motifId,
          userDetails.user_id
        );
      } catch (e) {
        setError("Failed to fetch motif");
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    fetchMotif();
  }, [motifId, calculatePrice, getMotifById, userDetails.user_id]);

  useEffect(() => {
    if (motif) {
      calculatePrice(
        quantity,
        motif.width,
        motif.height,
        motif.colors,
        motif.id,
        userDetails.user_id
      );
    }
  }, [motif, quantity, calculatePrice, userDetails.user_id]);

  const handleEdit = () => {
    navigate(`edit/`);
  };

  const handleImageError = () => {
    setImageError(true); // Set the error state to show the fallback icon and tooltip
  };

  const handleBack = () => {
    if (location.state?.from === "checkout") {
      navigate("/checkout");
    } else if (location.state?.from === "order-details") {
      const orderId = location.state?.orderId;
      navigate(`/dashboard/order/${orderId}`);
    } else if (location.state?.from === "admin-motif") {
      if (motif.reserved) {
        navigate("/dashboard/admin/all-motifs", { state: { tabIndex: 2 } });
      } else if (motif.approved) {
        navigate("/dashboard/admin/all-motifs", { state: { tabIndex: 1 } });
      } else {
        navigate("/dashboard/admin/all-motifs", { state: { tabIndex: 0 } });
      }
    } else {
      if (motif.approved) {
        navigate("/dashboard/motif", { state: { tabIndex: 2 } });
      } else if (motif.approved) {
        navigate("/dashboard/motif", { state: { tabIndex: 1 } });
      } else {
        navigate("/dashboard/motif", { state: { tabIndex: 0 } });
      }
    }
  };

  const addToCartOnClick = async () => {
    if (!motif) {
      console.error("No motif data available");
      return;
    }

    try {
      // Trigger the add to cart action which will sync with the backend
      await handleAddToCart(motif, quantity);
      setSuccessMessage(t("Dashboard.Motif.AddedToCart"));
      setAlertOpen(true);
    } catch (error) {
      setError("Failed to add to cart");
    }
  };

  const handleDownload = (url, name) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = name || "image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleApprove = async (id) => {
    try {
      await approveMotif(id);
      setSuccessMessage(t("Dashboard.Motif.ApproveSuccess"));
      setAlertOpen(true); // Show the snackbar alert
    } catch (error) {
      console.error("Failed to approve motif", error);
      setError(t("Dashboard.Motif.ApproveFailed"));
    }
  };

  const handleActive = async (id) => {
    try {
      await activateMotif(id);
      setSuccessMessage(t("Dashboard.Motif.ActiveSuccess"));
      setAlertOpen(true); // Show the snackbar alert
    } catch (error) {
      console.error("Failed to activate motif", error);
      setError(t("Dashboard.Motif.ActiveFailed"));
    }
  };

  useEffect(() => {
    if (alertOpen) {
      const timer = setTimeout(() => {
        window.location.reload();
      }, 2000);

      return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }
  }, [alertOpen]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <>
      {/* Snackbar for top-center alert */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        autoHideDuration={2000}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Paper elevation={3} sx={{ p: 2, mx: "auto" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{ position: "relative", width: "100%", textAlign: "center" }}
            >
              {!imageError ? (
                <img
                  src={motif?.imageUrl}
                  alt={motif?.name}
                  style={{
                    width: "100%",
                    padding: "2.5%",
                    height: "auto",
                    borderRadius: "8px",
                    backgroundColor: "gray",
                  }}
                  onError={handleImageError} // Handle error
                />
              ) : (
                <Tooltip
                  title="Could not load image. Unsupported file format."
                  arrow
                >
                  <ImageNotSupportedIcon
                    sx={{
                      fontSize: 100,
                      color: theme.palette.text.secondary,
                    }}
                  />
                </Tooltip>
              )}
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "center",
                  gap: 1,
                }}
              >
                <Tooltip title={t("Dashboard.Motif.TipDownload")} arrow>
                  <IconButton
                    onClick={() => handleDownload(motif.imageUrl, motif.name)}
                    sx={{
                      backgroundColor: theme.palette.grey[400],
                      borderRadius: 2,
                      padding: 1,
                      "& .MuiTouchRipple-root span": {
                        borderRadius: 2,
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.grey[500],
                      },
                    }}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("Dashboard.Motif.TipEdit")} arrow>
                  <IconButton
                    onClick={handleEdit}
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      borderRadius: 2,
                      padding: 1,
                      "& .MuiTouchRipple-root span": {
                        borderRadius: 2,
                      },
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                      },
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                {(userDetails?.accesslevel === "admin" ||
                  userDetails?.accesslevel === "superadmin") &&
                !motif?.approved &&
                motif?.active ? (
                  <Tooltip title={t("Dashboard.Motif.TipApprove")} arrow>
                    <IconButton
                      onClick={() => handleApprove(motif?.id)}
                      sx={{
                        backgroundColor: theme.palette.success.light,
                        borderRadius: 2,
                        padding: 1,
                        "& .MuiTouchRipple-root span": {
                          borderRadius: 2,
                        },
                        "&:hover": {
                          backgroundColor: theme.palette.success.main,
                        },
                      }}
                    >
                      <ApproveIcon />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {(userDetails?.accesslevel === "admin" ||
                  userDetails?.accesslevel === "superadmin") &&
                !motif?.active ? (
                  <Tooltip title={t("Dashboard.Motif.TipActive")} arrow>
                    <IconButton
                      onClick={() => handleActive(motif?.id)}
                      sx={{
                        backgroundColor: theme.palette.warning.main,

                        borderRadius: 2,
                        padding: 1,
                        "& .MuiTouchRipple-root span": {
                          borderRadius: 2,
                        },
                        "&:hover": {
                          backgroundColor: theme.palette.warning.dark,
                        },
                      }}
                    >
                      <PriorityHigh />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            {motif ? (
              <>
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Tooltip
                      title={
                        !motif.active
                          ? t("Article.Inactive")
                          : motif.reserved
                          ? t("Article.Reserved")
                          : isInCart
                          ? t("Article.InCart")
                          : ""
                      }
                      arrow
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          backgroundColor: isInCart
                            ? theme.palette.primary.main
                            : motif.active
                            ? theme.palette.secondary.main
                            : theme.palette.error.main,
                          textAlign: "center",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          paddingX={2}
                          color={
                            !motif.active || isInCart ? "white" : "text.main"
                          }
                          sx={{ fontWeight: "500" }}
                        >
                          {motif.article_number}
                        </Typography>
                      </Paper>
                    </Tooltip>
                  </Box>

                  <Tooltip title={t("Dashboard.Motif.GoBack")} arrow>
                    <IconButton aria-label="back" onClick={handleBack}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Typography variant="h5" fontWeight={600} gutterBottom>
                  {motif.name}
                </Typography>

                <Grid container my={2} mb={4}>
                  <Grid item xs={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.Width")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={17}
                      color={"text.light"}
                      fontWeight={400}
                      gutterBottom
                    >
                      {motif.width} mm
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.Height")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={17}
                      color={"text.light"}
                      fontWeight={400}
                      gutterBottom
                    >
                      {motif.height} mm
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.Area")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={17}
                      color={"text.light"}
                      fontWeight={400}
                      gutterBottom
                    >
                      {parseFloat(
                        (((motif.width / 10) * motif.height) / 10).toFixed(2)
                      ).toString()}{" "}
                      cm²
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.Color")}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize={17}
                      color={"text.light"}
                      fontWeight={400}
                      gutterBottom
                    >
                      {motif.colors}
                    </Typography>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Tooltip
                      title={`${t("Dashboard.Motif.CreatedAt")} ${formatTime(
                        motif.createdAt
                      )}`}
                      placement="bottom-start"
                      arrow
                    >
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color={"slateblue"}
                          sx={{ mb: 0.1, textTransform: "uppercase" }}
                        >
                          {t("Dashboard.Motif.CreatedAt")}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize={17}
                          color={"text.light"}
                          fontWeight={400}
                          gutterBottom
                        >
                          {formatDate(motif.createdAt)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Tooltip
                      title={`${t("Dashboard.Motif.UpdatedAt")} ${formatTime(
                        motif.updatedAt
                      )}`}
                      placement="bottom-start"
                      arrow
                    >
                      <Box>
                        <Typography
                          variant="subtitle2"
                          color={"slateblue"}
                          sx={{ mb: 0.1, textTransform: "uppercase" }}
                        >
                          {t("Dashboard.Motif.UpdatedAt")}
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize={17}
                          color={"text.light"}
                          fontWeight={400}
                          gutterBottom
                        >
                          {formatDate(motif.updatedAt)}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid container spacing={2} my={1} mb={2}>
                  <Grid item xs={6} md={5}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.PricePerAbbr")}
                    </Typography>
                    <Typography variant="body1" fontSize={21} fontWeight={500}>
                      {parseFloat(itemPriceInfo.pricePerMotif) % 1 === 0
                        ? parseInt(itemPriceInfo.pricePerMotif, 10)
                        : itemPriceInfo.pricePerMotif}{" "}
                      kr
                    </Typography>
                    {motif.colors > 1 && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Dashboard.Motif.BasePrice")}:{" "}
                        {parseFloat(
                          itemPriceInfo.pricePerMotif -
                            itemPriceInfo.discountedColorIncrease / quantity
                        ).toFixed(2)}{" "}
                        kr
                      </Typography>
                    )}
                    {motif.colors > 1 && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Dashboard.Motif.ColorImpact")}: +
                        {parseFloat(
                          itemPriceInfo.discountedColorIncrease / quantity
                        ).toFixed(2)}{" "}
                        kr
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 0.1, textTransform: "uppercase" }}
                    >
                      {t("Dashboard.Motif.Total")}
                    </Typography>
                    <Typography variant="body1" fontSize={23} fontWeight={500}>
                      {parseFloat(itemPriceInfo.totalWOLump) % 1 === 0
                        ? parseInt(itemPriceInfo.totalWOLump, 10)
                        : itemPriceInfo.totalWOLump}{" "}
                      kr
                    </Typography>
                    {motif.colors > 1 && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Dashboard.Motif.BasePrice")}:{" "}
                        {parseFloat(
                          itemPriceInfo.totalWOLump -
                            itemPriceInfo.discountedColorIncrease
                        ) %
                          1 ===
                        0
                          ? parseInt(
                              itemPriceInfo.totalWOLump -
                                itemPriceInfo.discountedColorIncrease,
                              10
                            )
                          : parseFloat(
                              itemPriceInfo.totalWOLump -
                                itemPriceInfo.discountedColorIncrease
                            ).toFixed(2)}{" "}
                        kr
                      </Typography>
                    )}

                    {motif.colors > 1 && (
                      <Typography variant="body2" color="text.secondary">
                        {t("Dashboard.Motif.ColorImpact")}: +
                        {parseFloat(itemPriceInfo.discountedColorIncrease) %
                          1 ===
                        0
                          ? parseInt(itemPriceInfo.discountedColorIncrease, 10)
                          : itemPriceInfo.discountedColorIncrease}{" "}
                        kr
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Box mt={1}>
                  <Tooltip
                    title={t("Dashboard.Motif.QuantityWarn")}
                    open={quantityWarning}
                    arrow
                    placement="top"
                  >
                    <TextField
                      label={t("Dashboard.Motif.SelQuant")}
                      value={quantity}
                      size="small"
                      variant="outlined"
                      type="number"
                      onChange={(e) => {
                        const newQuantity = parseInt(e.target.value, 10);
                        if (isNaN(newQuantity) || newQuantity < 10) {
                          setQuantityWarning(true);
                          setQuantity(10);
                        } else {
                          setQuantityWarning(false);
                          setQuantity(newQuantity);
                        }
                      }}
                      sx={{ width: "150px" }}
                    />
                  </Tooltip>

                  <Tooltip
                    title={
                      motif.approved ? "" : t("Dashboard.Motif.WaitApprove")
                    }
                    arrow
                  >
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!motif.approved}
                        onClick={addToCartOnClick}
                        sx={{ ml: 1 }}
                      >
                        {t("Dashboard.Motif.AddCart")}
                      </Button>
                    </span>
                  </Tooltip>
                  {!motif.approved && (
                    <Alert
                      severity="info"
                      style={{
                        backgroundColor: alpha(theme.palette.info.main, 0.15),
                      }}
                      sx={{ mt: 2 }}
                    >
                      {t("Dashboard.Motif.WaitApprove")}
                    </Alert>
                  )}
                </Box>
              </>
            ) : (
              <Typography variant="body1">
                {t("Dashboard.Motif.NotFound")}
              </Typography>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default MotifPage;
