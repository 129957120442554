import { useAuth } from "../../hooks/useAuth";
import UnderMaintenance from "../../pages/NotFound/UnderMaintenance";


const ProtectedDashboardRoute = ({ children }) => {
  const { userDetails } = useAuth()

  if (userDetails?.inactive) {
    return <UnderMaintenance />;
  }

  return children;
};

export default ProtectedDashboardRoute;
